import React, { useState, useEffect } from 'react'
import { generateTrackingPath, getAppBase } from '../lib/helpers';
import Graph from "../images/graph.png"

import styles from "./CTA.module.css";

const CTA = props => {

  const [registerPath, setRegisterPath] = useState('/register/');
  const appBase = getAppBase();


  useEffect(() => {
    setRegisterPath(generateTrackingPath('/register/'));
  }, []);

  if (!props || !props.ctaTitle || !props.ctaDesc ) return null

  return (
    <>
      <div className={styles.mainGetStarted}>
        <div className={styles.gsImgBox}>
          <img src={Graph} alt="" role="presentation"/>
        </div>
        <div className={styles.gsCopy}>
          <h2>{props.ctaTitle}</h2>
          <p>{props.ctaDesc}</p>
          <a className={styles.getStarted} href={`https://${appBase}${registerPath}`}>Get Started</a>
        </div>
      </div>
    </>
  )
}

export default CTA
